





























import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import { Component, Prop, Vue } from 'vue-property-decorator';
import DxIcons from "../../../../../Component/Common/Icons/Icons.vue";
import DxDrop from "../../../../../Component/Common/Drop/Drop.vue";
import DxLinkWrap from '../../../../../Component/Common/LinkWrap/LinkWrap.vue';
import DxAsset from "../../../../../Component/Common/Assets/Image/Image.vue";

@Component({
    name: 'dx-text',
    components: {DxIcons, DxDrop, DxAsset, DxLinkWrap}
})
export default class Text extends Vue
{
    @Prop() readonly context: ContentElementContext;
    @Prop({default: true}) readonly animate: boolean;

    _contentElement: any;

    created()
    {
        // console.log('[Text] _contentElement' , this.context.data.getAll());
        this._contentElement = this.context.data.getAll();
    };

    get contentElement()
    {
        return this._contentElement
    }

    get classes()
    {
        let classes = '';
        if (this._contentElement && this._contentElement.spacer)
        {
            classes += this._contentElement.spacer.margin + ' ' + this._contentElement.spacer.padding;
        }

        if (this._contentElement.background)
        {
            classes += ' ' + this._contentElement.background;
        }

        return classes;
    }
};
