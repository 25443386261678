var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      directives: [
        {
          name: "animate-onscroll",
          rawName: "v-animate-onscroll",
          value: _vm.animate
            ? "animate__animated animate__slideUpAndAppear animate__fast"
            : "",
          expression:
            "animate ? 'animate__animated animate__slideUpAndAppear animate__fast' : ''",
        },
      ],
      staticClass: "dx-text",
      class: _vm.classes,
    },
    [
      _c(
        "div",
        { staticClass: "dx--container relative" },
        [
          _vm.contentElement.subHeader
            ? _c(
                "h4",
                {
                  staticClass:
                    "text-md md:text-md font-light uppercase mb-4 flex flex-row items-center",
                },
                [
                  _vm.contentElement.icon
                    ? _c("dx-asset", {
                        staticClass: "max-w-xs mr-2",
                        attrs: { image: _vm.contentElement.icon },
                      })
                    : _vm._e(),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.contentElement.subHeader) +
                      "\n        "
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.contentElement.header
            ? _c(
                "h2",
                {
                  staticClass:
                    "text-3xl sm:text-3xl md:text-4xl tracking-tight font-bold text-black-500",
                },
                [
                  _c("span", { staticClass: "block xl:inline" }, [
                    _vm._v(_vm._s(_vm.contentElement.header)),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.contentElement.bodytext
            ? _c(
                "p",
                {
                  staticClass:
                    "mt-3 md:mt-5 mx-auto text-base text-black-500 sm:text-lg md:text-xl",
                },
                [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(_vm.contentElement.bodytext),
                    },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("dx-icons", {
            attrs: {
              icons: _vm.contentElement.icons,
              classes: "mt-5 md:mt-10",
            },
          }),
          _vm._v(" "),
          _vm.contentElement.cta && _vm.contentElement.cta.length
            ? _c(
                "div",
                { staticClass: "text-center mt-12" },
                _vm._l(_vm.contentElement.cta, function (item, key) {
                  return _c(
                    "div",
                    { key: key, staticClass: "flex flex-col" },
                    [
                      item.text
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "text-xl font-bold text-black-500 sm:text-2xl md:text-3xl",
                            },
                            [_vm._v(_vm._s(item.text))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      item.link
                        ? _c(
                            "dx-link-wrap",
                            {
                              staticClass:
                                "font-semibold text-normal uppercase",
                              class: { "mt-8": item.text },
                              attrs: {
                                variant: "blue",
                                outline: true,
                                centered: false,
                                link: item.link,
                                rounded: true,
                                "is-button": true,
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "tracking-widest hyphens" },
                                [_vm._v(_vm._s(item.label))]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.contentElement.drop
            ? _c("dx-drop", {
                attrs: { type: _vm.contentElement.drop, relative: false },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }